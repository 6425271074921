import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from '../services/HttpClientService';
import {ApiConfigs} from '../Configs/api-Configs';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  sevaNo: String;
  tileName: String;
  totReceving: Number;
  transactionList: any;

  constructor(private router: Router,
              private http: HttpClientService,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParam => {
      this.sevaNo = queryParam.sevaNo;
      this.tileName = queryParam.tileName;
      this.getTransactionData (queryParam.sevaNo);
    });
    this.transactionList = [];
  }

  ngOnInit() {
  }
  getTransactionData(sevaNo) {
    this.http.post(ApiConfigs.GET_TRANSACTIONS, {tileNo: sevaNo},
      (data) => {
        this.transactionList = data.data.rows;
        this.transactionList.forEach(o => {
          this.totReceving = this.totReceving || 0;
          this.totReceving += o.cell[0];
        });
      }, (error) => {
        alert(error.message);
        this.router.navigate(['/']);
      });
  }
  backToDashboard() {
    this.router.navigate(['home/dashboard']);
  }

}
