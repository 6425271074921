import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {UserData} from '../../services/UserData';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Output() onSelectChange = new EventEmitter();
  constructor(private router: Router,
              public userData: UserData) { }

  ngOnInit() {
  }

  onChange() {
    this.onSelectChange.emit();
  }
}
