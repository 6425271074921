import {Component, OnInit} from '@angular/core';
import * as Marzipano from 'marzipano';
import {Room} from '../classes/Room';
//import * as Hammer from 'hammerjs';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from '../services/HttpClientService';
import {ApiConfigs} from '../Configs/api-Configs';

// Create geometry.
const geometry = new Marzipano.EquirectGeometry([{width: 4000}]);

// Create view.
const limiter = Marzipano.RectilinearView.limit.traditional(1024, 80 * Math.PI / 180);
const view = new Marzipano.RectilinearView({yaw: 0}, limiter);

@Component({
  selector: 'app-upsert-room',
  templateUrl: './upsert-room.component.html',
  styleUrls: ['./upsert-room.component.css']
})
export class UpsertRoomComponent implements OnInit {
  _id;
  oRoom;
  viewer: Marzipano.Viewer;
  autorotate = Marzipano.autorotate({
    yawSpeed: 0.1,         // Yaw rotation speed
    targetPitch: 0,        // Pitch value to converge to
    targetFov: 100 * Math.PI / 180   // Fov value to converge to
  });
  scene;
  room = new Room({
    name: '',
    image: 'main_gate.jpg',
    hotspots: []
  });
  rooms: any;
  walls: any;
  constructor(private router: Router,
              private http: HttpClientService,
              private route: ActivatedRoute) {
    const mContext = this;
    this.route.queryParams.subscribe(queryParam => {
      mContext._id = queryParam._id;
      mContext.oRoom = null;
      mContext.getRooms ({_id: queryParam._id}, true);
    });
    this.getRooms({});
    this.getWalls({});
  }

  getRooms (filter, forUpdate?) {
    this.http.get(ApiConfigs.GET_ROOM, filter,
      (data) => {
        if (forUpdate) {
          this.oRoom = data.data[0];
          this.room = new Room(this.oRoom);
          this.changeImage(true);
        } else {
          this.rooms = data.data;
        }
      }, (error) => {});
  }
  getWalls (filter) {
    this.http.get(ApiConfigs.GET_WALL, filter,
      (data) => {
        this.walls = data.data;
      }, (error) => {});
  }

  ngOnInit() {
    // Create viewer.
    this.viewer = new Marzipano.Viewer(document.getElementById('pan'));
    // this.viewer.setIdleMovement(100, this.autorotate);
    // Create source.
    const source = Marzipano.ImageUrlSource.fromString('./assets/jpg/' + this.room.image);

    // Create scene.
    this.scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
    });

    // Display scene.
    this.scene.switchTo();

    // this.viewer.setIdleMovement(3000, this.autorotate);
  }

  changeImage(preserveHs?) {
    let tempHs = [];
    if (preserveHs) {
      tempHs = (this.room.json()).hotspots;
    }
    for (let i = this.room.hotspots.length; i > 0; i--) {
      this.removeHotspot(i - 1);
    }

    const tempScene = this.scene;

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString('./assets/jpg/' + this.room.image);

    // Create scene.
    this.scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
    });

    // Display scene.
    this.scene.switchTo({
      transitionDuration: 2000
    });
    setTimeout(() => {
      this.viewer.destroyScene(tempScene);
      for (const hs of tempHs) {
        this.room.hotspots.push(this.plotHotspot(hs));
      }
    }, 2100);

  }

  plotHotspot(hotspot: any) {
    const rmHs = document.getElementById('rmhs');
    const wlHs = document.getElementById('wlhs');
    let imgHotspot;
    if (hotspot.category === 'Room') {
      imgHotspot = rmHs.cloneNode();
    } else if (hotspot.category === 'Wall') {
      imgHotspot = wlHs.cloneNode();
    }
    (<any>imgHotspot).hotspot = this.scene.hotspotContainer().createHotspot(imgHotspot, hotspot.position);
    hotspot.hotspot = (<any>imgHotspot).hotspot;
    return hotspot;
  }

  onPan (ev) {
    const hotspot = ev.target.hotspot;
    if (ev.distance > 0) {
      hotspot.setPosition({
        yaw: ((ev.target.startPos.yaw - ev.deltaX) * Math.PI) / 2048,
        pitch: ((ev.target.startPos.pitch - ev.deltaY) * Math.PI) / 2048,
      });
    }
  }

  changePosition (hotspot) {
    hotspot.hotspot.setPosition(hotspot.position);
  }

  removeHotspot (index) {
    const hsContainer = this.scene.hotspotContainer();
    if (hsContainer.hasHotspot(this.room.hotspots[index].hotspot)) {
      hsContainer.destroyHotspot(this.room.hotspots[index].hotspot);
    }
    this.room.hotspots.splice(index, 1);
  }

  addHotspot (category) {
    let hotspot: any;
    hotspot = {
      category: category
    };
    const currentView = this.scene.view();
    hotspot.position = {yaw: currentView.yaw(), pitch: currentView.pitch()};
    this.room.hotspots.push(this.plotHotspot(hotspot));
  }

  submit () {
    if (this.oRoom && this.oRoom._id) {
      this.http.post(ApiConfigs.UPDATE_ROOM + this.oRoom._id, this.room.json(),
        (data) => {
          alert(data.message);
        }, (error) => {
        });
    } else {
      this.http.post(ApiConfigs.ADD_ROOM, this.room.json(),
        (data) => {
          alert(data.message);
        }, (error) => {
        });
    }
  }

  selectRoom() {
    this.router.navigate(['adminHome/addRoom'], {
      queryParams: { ...(this._id != null ? {'_id': this._id} : {}) }});
  }

}
