import {Component, OnInit, AfterViewInit, ElementRef, ViewChild} from '@angular/core';
import {UserData} from '../services/UserData';
import {ActivatedRoute,Router} from '@angular/router';
import {ApiConfigs} from '../Configs/api-Configs';
import {ServerConfigs} from '../Configs/serverConfigs';
import {HttpClientService} from '../services/HttpClientService';

declare const $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements AfterViewInit {
  cart;
  cartCount;
  order: any; /*{
    amount: 0,
    month: 12,
    schemes: 'Monthly',
    emi: 0,
    count: 0
  };*/
  schemes = [{
    name: '100/- Monthly for 5 years per Brick',
    value: 60
  }, {
    name: '200/- Monthly for 30 months per Brick',
    value: 30
  }, {
    name: '500/- Monthly for 12 months per Brick',
    value: 12
  }, {
    name: '1000/- Monthly for 6 month per Brick',
    value: 6
  }, {
    name: '6000/- One Time per Brick',
    value: 0
  }];
  private serverConfigs = new ServerConfigs();
  submitApi = this.serverConfigs.httpHost + ApiConfigs.PAY_SUCCESS;
  modal: any;
  constructor(private http: HttpClientService,
              public userData: UserData,
              private router: Router,
              private route: ActivatedRoute) {
    this.order = {};
    this.modal = {};
    this.userData._cart.subscribe((cart) => this.cart = cart);
    const order = JSON.parse(localStorage.getItem('order'));
    if (order) {
      this.order = order;
      /*this.order.name = [];
      for (let i = 0; i < order.count; i++) {
        this.order.name.push('Nipun' + i);
      }*/
    } else {
      alert('Please provide donation details first!');
      this.router.navigate(['home', 'order']);
    }
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(queryParam => {
      if (queryParam.donate && queryParam.donate === 'random') {
        this.assignTile(true);
      }
    });
  }

  get keys(): Array<string> {
    return Object.keys(this.cart);
  }

  goToWall(wallId) {
    this.router.navigate(['./home/donate'], {
      queryParams: { '_id': wallId }});
  }

  removeBrick(cartObj, index) {
    const items = [...cartObj.items];
    items.splice(index, 1);
    this.userData.setCart(cartObj.data, items);
  }

  addOrder() {
    if (!this.order.amount || !this.order.installments || this.order.count < 1) {
      alert ('Fill the form!');
      return;
    } else {
      this.http.post(ApiConfigs.DONATE ,
        {cart: JSON.parse(this.userData.cartString), order: this.order},
        (data) => {
          this.launchPay(data.data);
        }, (error) => {
          alert(error.message);
        });
    }
    // this.launchPay();
  }

  launchPay(bkOrder) {
    let btn;
    if (btn = $('[value="Pay"]')[0]) {
      btn.click();
    } else {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.type = 'text/javascript';
      script.onload = () => {
        $('[value="Pay"]')[0].click();
      };
      script.setAttribute('data-key', this.serverConfigs.razorkey);
      script.setAttribute('data-amount', bkOrder.amount);
      script.setAttribute('data-order_id', bkOrder.id);
      script.setAttribute('data-currency', 'INR');
      script.setAttribute('data-buttontext', 'Pay');
      script.setAttribute('data-name', 'ISKCON Dwarka');
      script.setAttribute('data-description', `Donate ${this.order.count} Tile`);
      script.setAttribute('data-image', './assets/iskcon-logo.png');
      // script.setAttribute('data-prefill.name', 'Nipun Bhardwaj');
      // script.setAttribute('data-prefill.email', 'test@nipunbhardwaj.com');
      script.setAttribute('data-theme.color', '#00008B');
      document.getElementById('payForm').innerHTML = '';
      document.getElementById('payForm').appendChild(script);
    }
  }

  onSubmit() {
    console.log('Submitted');
    alert('Submitted');
  }

  useSameName(i) {
    for (let x = i; x < this.order.count; x++) {
      this.order.name[x] = this.order.name[i];
    }
  }

  showModal() {
    const modal = document.getElementById('modal');
    modal.classList.remove('invisible');
  }

  assignTile(random) {
    const mContext = this;
    this.http.post(ApiConfigs.ASSIGN_TILE ,
      {cart: JSON.parse(this.userData.cartString), assignRandom: random},
      (data) => {
        localStorage.removeItem('cart');
        localStorage.removeItem('order');
        this.modal = {
          title: 'Thank You',
          message: data.message,
          routeTo: data.user && data.user.name ? '/home/dashboard' : '/home/profile',
          query: data.user && data.user.name ? {} : {mode: 'edit', origin: 'payment'}
        };
        this.showModal();
      }, (error) => {
        alert(error.message);
      });
  }
}
