import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClientService} from "../services/HttpClientService";
import {ApiConfigs} from "../Configs/api-Configs";

@Component({
  selector: 'app-data-view',
  templateUrl: './data-view.component.html',
  styleUrls: ['./data-view.component.css']
})
export class DataViewComponent implements OnInit {
  otpSent = false;
  mobile;
  otp;
  timeleft;
  disableSend = false;
  sevaData: any;

  constructor(private router: Router,
              private http: HttpClientService,
              private route: ActivatedRoute) { }

  ngOnInit() {
  }

  sendOtp() {
    const mContext = this;
    if (!this.disableSend) {
      this.http.get(ApiConfigs.SEND_OTP + this.mobile,
        {},
        (data) => {
          mContext.otpSent = true;
          mContext.disableSend = true;
          mContext.timeleft = 40;
          const timerId = setInterval(() => mContext.timeleft > 0 && mContext.timeleft--, 1000);
          setTimeout(() => {
            clearInterval(timerId);
            mContext.timeleft = null;
            mContext.disableSend = false;
          }, 40000);
        }, (error) => {
          alert(error.message);
        });
    }
  }

  getSevaData() {
    const mContext = this;
    this.http.get(ApiConfigs.SEVA_DATA,
      { mobile: mContext.mobile, otp: mContext.otp },
      (data) => {
        mContext.sevaData = data.data;
      }, (error) => {
        alert(error.message);
      });
  }
}
