import { Component, OnInit } from '@angular/core';
import {ApiConfigs} from '../Configs/api-Configs';
import {ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {HttpClientService} from 'src/app/services/HttpClientService';

@Component({
  selector: 'app-sync-data',
  templateUrl: './sync-data.component.html',
  styleUrls: ['./sync-data.component.css']
})
export class SyncDataComponent implements OnInit {
  resyncData: any=[]
  pageNo: any = 0;
  scrollEvent: any = true;

  constructor(
    private http: HttpClientService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.resyncTableData(this.pageNo);
  }
  resyncTableData(pageNo){
     let req={
      no_of_docs : 15,
        limit: 15,
       skip: pageNo,
     }

    this.http.get(ApiConfigs.ADMIN_RESYNC_TABLE,req,
      (data) => {
        if (data && data.data && data.data.length > 0) {
        
          this.resyncData.push(...data.data);
       
      } else {
          this.scrollEvent = false;
      }
      }, (error) => {
        alert(error.message);
      });
  }

  onScroll() {
    if (this.scrollEvent) {
        console.log('scrolled!!');
        this.pageNo = this.pageNo + 1;
        console.log(this.pageNo);
        this.resyncTableData(this.pageNo);
    }
}
}
