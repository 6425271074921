import { UserData } from './services/UserData';
import { Component, OnInit } from '@angular/core';
import {ApiConfigs} from "./Configs/api-Configs";
import {HttpClientService} from "./services/HttpClientService";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  userToken;
  adminToken;
  myData;

  constructor(private userD: UserData,
              private http: HttpClientService) {
    userD.tokenAuth.next(localStorage.getItem('token'));
    userD.admintokenAuth.next(localStorage.getItem('admintoken'));
    userD.tokenAuth.subscribe(tokenvalue => {
      this.userToken = tokenvalue || '';
    });
    userD.admintokenAuth.subscribe(adminTokenValue => {
      this.adminToken = adminTokenValue || '';
      if (this.adminToken)this.getMydetails();
    });
  }

  getMydetails() {
    const mContext = this;
    this.http.get(ApiConfigs.MY_DETAILS, {},
      (body) => {
        mContext.myData = body.data;
        mContext.userD.adminData.next(body.data);
      }, (error) => {
        alert(error.message);
      });
  }

}
