import {Brick} from './Brick';
import {el} from '@angular/platform-browser/testing/src/browser_util';
import {UserData} from '../services/UserData';
// tslint:disable-next-line: import-blacklist
import {BehaviorSubject} from 'rxjs/Rx';

export class Bricks {
    get donor() {
        return this._donor;
    }

    set donor(value) {
        this._donor = value;
    }

    get om() {
        return this._om;
    }

    set om(value) {
        this._om = value;
    }

    get bricks(): Array<any> {
        return this._bricks;
    }

    set price(value: number) {
        this._price = value;
    }

    set name(value: string) {
        this._name = value;
    }

    get name(): string {
        return this._name;
    }

    get totalSelectedWorth(): number {
        return this._totalSelectedWorth;
    }

    get price(): number {
        return this._price;
    }

    get selectedBricks(): any {
        return this._selectedBricks;
    }

    private _name: string;
    private _X: number;
    private _Y: number;
    private _price: number;
    private _totalSelectedWorth = 0;
    private _bricks = [];
    private _selectedBricks = [];
    private _om;
    private _donor;
    _selectedBricksWatcher = new BehaviorSubject<any>([]);

    constructor(x: number, y: number, price: number, name: string, wall?: any, private preselected?) {
        this._X = x;
        this._Y = y;
        this._price = price;
        this._name = name;
        if (!wall || !wall.bricks) {
            this.generateBrickArray(x, y, price);
        } else {
            this.traverseBricksArray(wall.bricks);
        }
    }

    traverseBricksArray(bricks) {
        const mContext = this;
        this._bricks = [];
        for (const row of bricks) {
            const arr1 = [];
            for (const br of row.row) {
                const brick = new Brick(0, 0, br.price, br);
                const i = mContext.preselected.findIndex((b) => b.id === brick.id);
                brick.selectedWatcher.subscribe((isSelected) => {
                    const index = mContext._selectedBricks.findIndex((b) => b.id === brick.id);
                    if (isSelected && index < 0) {
                        mContext._selectedBricks.push(brick);
                    } else if (!isSelected && index > -1) {
                        mContext._selectedBricks.splice(index, 1);
                    }
                    mContext._selectedBricksWatcher.next(mContext._selectedBricks);
                    mContext._totalSelectedWorth = mContext._selectedBricks.reduce((a, c) => a + c.price, 0);
                });
                if (i > -1) {
                    brick.selected = true;
                }
                arr1.push(brick);
            }
            this._bricks.push(arr1);
        }
    }

    private generateBrickArray(x: number, y: number, price: number) {
        const mContext = this;
        this._bricks = [];
        for (let i = 0; i < y; i++) {
            const arr1 = [];
            for (let j = 0; j < x; j++) {
                const brick = new Brick(i, j, price);
                brick.selectedWatcher.subscribe((isSelected) => {
                    const index = mContext._selectedBricks.findIndex((b) => b.id === brick.id);
                    if (isSelected && index < 0) {
                        mContext._selectedBricks.push(brick);
                    } else {
                        mContext._selectedBricks.splice(index, 1);
                    }
                    mContext._totalSelectedWorth = mContext._selectedBricks.reduce((a, c) => a + c.price, 0);
                });
                arr1.push(brick);
            }
            this._bricks.push(arr1);
        }
    }

    get X() {
        return this._X;
    }

    get Y() {
        return this._Y;
    }

    set X(value: number) {
        this._X = value;
        this.generateBrickArray(this._X, this._Y, this._price);
    }

    set Y(value: number) {
        this._Y = value;
        this.generateBrickArray(this._X, this._Y, this._price);
    }

    deselectAll() {
        for (const brickRow of this._bricks) {
            for (const brick of brickRow) {
                brick.selected = false;
            }
        }
    }

    hideSelected() {
        for (const brickRow of this._bricks) {
            for (const brick of brickRow) {
                if (brick.selected) {
                    brick.visible = false;
                }
            }
        }
        this.deselectAll();
    }

    unHideSelected() {
        for (const brickRow of this._bricks) {
            for (const brick of brickRow) {
                if (brick.selected) {
                    brick.visible = true;
                }
            }
        }
        this.deselectAll();
    }

    json() {
        const obj = {
            name: this._name,
            X: this._X,
            Y: this._Y,
            price: this._price,
            bricks: []
        };
        for (const brickRow of this._bricks) {
            const arr1 = [];
            for (const brick of brickRow) {
                arr1.push({
                    id: brick.id,
                    visible: brick.visible,
                    owner: brick.owner,
                    price: brick.price
                });
            }
            obj.bricks.push({row: arr1});
        }
        return obj;
    }

    get selectedIds() {
        const ids = [];
        for (const b of this._selectedBricks) {
            ids.push(b.id);
        }
        return ids;
    }

    addOwners(donors: any, highlightMobile) {
        const arr2 = donors.filter(o => o.mobile === highlightMobile);
        let column = 1, row = 1, wallRow = 0, brk, oFound, temp;

        arr2.forEach(o => {
            brk = 'B ' + column + ' ' + row,
                oFound = donors.find((obj) => {
                    return obj.bricks.indexOf(brk) > -1;
                });
            if (oFound) {
                temp = oFound.bricks;
                oFound.bricks = o.bricks;
                o.bricks = temp;
                o.highlight = true;
            }
            if (this._bricks[wallRow].length > row) {
                row++;
            } else {
                row = 1;
                column++;
                wallRow++;
            }

        });
        for (const rows of (this._bricks)) {
            for (const br of rows) {
                let oFound1, oFound2;
                oFound1 = donors.find((obj) => {
                    return obj.bricks.indexOf(br.id) > -1;
                });
                oFound2 = arr2.find((obj) => {
                    return obj.bricks.indexOf(br.id) > -1;
                });
                if (oFound2) {
                    br.owner = oFound2;
                    br.highlight = oFound2.highlight ? true : false;
                } else {
                    br.owner = oFound1;
                }
            }
        }
    }
}
