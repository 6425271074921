import {BehaviorSubject} from 'rxjs/BehaviorSubject';

export class Brick {
  set owner(value: any) {
    this._owner = value;
  }
  get selected(): boolean {
    return this._selected.value;
  }
  get selectedWatcher() {
    return this._selected;
  }
  set selected(value: boolean) {
    this._selected.next(value);
  }
  get price(): number {
    return this._price;
  }
  get owner(): any {
    return this._owner;
  }
  get id() {
    return this._id;
  }
  private _id: string;
  private _selected = new BehaviorSubject<boolean>(false);
  visible = true;
  highlight = false;
  private _owner: any;
  private _price: number;
  constructor(x: number, y: number, price: number, brick?: any) {
    this._id = `B ${x + 1} ${y + 1}`;
    this._price = price;
    if (brick) {
      this._id = brick.id;
      this.visible = brick.visible;
      this._owner = brick.owner;
      this._price = brick.price;
    }
  }

}
