import { Component, OnInit } from '@angular/core';
import { ApiConfigs } from 'src/app/Configs/api-Configs';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpClientService } from 'src/app/services/HttpClientService';

@Component({
  selector: 'app-admin-add-user',
  templateUrl: './admin-add-user.component.html',
  styleUrls: ['./admin-add-user.component.css']
})
export class AdminAddUserComponent implements OnInit {
  selectedItem;
  admins: any;
  submitted;
  constructor(
    private http: HttpClientService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.getAdmin();

  }

  ngOnInit() {
  }

  showIcon(value) {
    console.log(value);
    if (value === this.selectedItem) {
      this.selectedItem = '';
    } else {
      this.selectedItem = value;
    }

  }
  userfields() {
    const navigationExtras: NavigationExtras = {
      queryParams: { '_id': this.selectedItem._id },
      relativeTo: this.route
    };
    this.router.navigate(['/adminHome/addUser'], navigationExtras);
  }

  getAdmin() {
    this.http.get(ApiConfigs.GET_ADMIN, {},
      (data) => {
        this.admins = data.data;
      }, (error) => {
        alert(error.message);
      });
  }

}
