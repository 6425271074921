import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-adminhome',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        flex: '1 0 200px',
        'max-width': '200px'
      })),
      state('closed', style({
        flex: '0 1 0'
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
  templateUrl: './adminhome.component.html',
  styleUrls: ['./adminhome.component.css']
})
export class AdminhomeComponent implements OnInit {
  isClosed;
  cartCount;
  orderCount;
  constructor() { }

  ngOnInit() {
  }

}
