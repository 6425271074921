import { Component, OnInit } from '@angular/core';
import {ApiConfigs} from '../Configs/api-Configs';
import {ServerConfigs} from '../Configs/serverConfigs';

declare const $: any;

@Component({
  selector: 'app-instant-pay',
  templateUrl: './instant-pay.component.html',
  styleUrls: ['./instant-pay.component.css', '../login/login.component.css']
})
export class InstantPayComponent implements OnInit {
  order: any;

  private serverConfigs = new ServerConfigs();
  submitApi = this.serverConfigs.httpHost + ApiConfigs.CAPTURE;
  constructor() {
    this.order = {
      count: 1,
      installments: 1
    };
    this.schemeChange();
  }

  ngOnInit() {
  }

  schemeChange() {
    this.order.netPrice = this.order.count * 6000;
    if (this.order.installments > 0) {
      this.order.emi = (this.order.netPrice / this.order.installments) || 100;
      this.order.amount = this.order.emi;
    } else {
      this.order.amount = this.order.count * 6000 ;
      this.order.emi = 0;
    }
  }

  launchPay() {
    let btn;
    if (btn = $('[value="Pay"]')[0]) {
      btn.click();
    } else {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.type = 'text/javascript';
      script.onload = () => {
        $('[value="Pay"]')[0].click();
      };
      script.setAttribute('data-key', this.serverConfigs.razorkey);
      script.setAttribute('data-amount', (this.order.amount * 100).toString());
      script.setAttribute('data-currency', 'INR');
      script.setAttribute('data-buttontext', 'Pay');
      script.setAttribute('data-name', 'ISKCON Dwarka');
      script.setAttribute('data-description', `Donate ${this.order.count} Tile on name ${this.order.name}`);
      script.setAttribute('data-image', './assets/iskcon-logo.png');
      // script.setAttribute('data-prefill.name', 'Nipun Bhardwaj');
      // script.setAttribute('data-prefill.email', 'test@nipunbhardwaj.com');
      script.setAttribute('data-theme.color', '#00008B');
      const payForm = document.getElementById('payForm');
      payForm.innerHTML = '';
      payForm.appendChild(script);
    }
  }

  showBrochure() {
    $('#brochure').addClass('hidden');
  }
}
