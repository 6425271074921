import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { UserData } from '../services/UserData';

declare const $: any;
@Component({
  selector: 'app-home',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        flex: '1 0 200px',
        'max-width': '200px'
      })),
      state('closed', style({
        flex: '0 1 0'
      })),
      transition('open => closed', [
        animate('0.3s')
      ]),
      transition('closed => open', [
        animate('0.3s')
      ]),
    ]),
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isClosed = true;
  cartCount;
  order;
  // userToken = localStorage.getItem('token');
  // adminToken = localStorage.getItem('admintoken');



  constructor(private router: Router,
    private route: ActivatedRoute,
    private userData: UserData) {
    const usertoken = localStorage.getItem('token');
    const adminToken = localStorage.getItem('admintoken');
    const usermobileNumber = localStorage.getItem('usermobilenumber');
    this.userData._cartCount.subscribe((cartCount) => this.cartCount = cartCount);
    const order = JSON.parse(localStorage.getItem('order'));
    if (order) {
      this.order = order;
    }

  }

  ngOnInit() {
    const mContext = this;
    document.onclick = (event) => {
      const $target = $(event.target);
      if ($target.closest('#content').length &&
        !mContext.isClosed) {
        mContext.isClosed = true;
      }
    };
  }

  get orderCount() {
    if (this.order && this.order.count && this.order.count > 0) {
      return this.order.count;
    } else {
      return 0;
    }
  }

}
