import { Component, OnInit } from '@angular/core';
import { ApiConfigs } from 'src/app/Configs/api-Configs';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpClientService } from 'src/app/services/HttpClientService';
// import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
// import { InfiniteScroll } from 'ngx-infinite-scroll';
import * as moment from 'moment';

@Component({
    selector: 'app-admin-control-panel',
    templateUrl: './admin-control-panel.component.html',
    styleUrls: ['./admin-control-panel.component.css']
})
export class AdminControlPanelComponent implements OnInit {
    selectedItem;
    filter: any;
    donationsAdminList: any = [];
    dateRangeValue: Date[];
    sevaNo: any;
    email: any;
    mobile: any;
    fromDate: any;
    disableBtn:any = false;
    visiblebtn:any = true;
    toDate: any;
    maxDate: Date;
    pageNo: any = 0;
    scrollEvent: any = true;
    moment: any = moment;
    selectedOne1: any;
    sevaNumber1: any;
    fromDate1: any;
    toDate1: any;
    req: any;
    walls;
    _id: any;
    sevaNum: any;

    constructor(
        private http: HttpClientService,
        private route: ActivatedRoute,
        private router: Router) {
        this.maxDate = new Date();
    }

    ngOnInit() {
        this.getAdminData(this.pageNo);
    }

    getAdminData(pageNo) {
        // if (scroll) { pageNo = 0; }
        // START :: When search filter applied
        if (this.dateRangeValue) {
            console.log(this.dateRangeValue);
            this.fromDate = new Date(this.dateRangeValue[0]);
            this.toDate = new Date(this.dateRangeValue[1]);
        } else {
            this.fromDate = undefined;
            this.toDate = undefined;
        }

        let req = {
            email: this.email,
            to: this.toDate,
            from: this.fromDate,
            mobile: this.mobile,
            sevaNumber: this.sevaNo,
            limit: 15,
            skip: pageNo,
        };
        if (this.sevaNo) {
            req.sevaNumber = parseInt(this.sevaNo);
        }

        // END :: When search filter applied
        this.http.post(ApiConfigs.ADMIN_DONATIONS, req,
            (data) => {
                if (data.data && data.data.data && data.data.data.length > 0) {
                    data.data.data.forEach(o => {
                        if (o.mobile) {
                            o.mobile = o.mobile.replace(/[^0-9]/g, '');
                        }
                    });
                    this.donationsAdminList.push(...data.data.data);
                    this.selectedItem = this.donationsAdminList[0];
                } else {
                    this.scrollEvent = false;
                }
            }, (error) => {
                alert(error.message);
            });
    }

    showIcon(value) {
        console.log(value);
        if (value === this.selectedItem) {
            this.selectedItem = '';
        } else {
            this.selectedItem = value;
        }
    }

    showSevaList(donor) {
        this.router.navigate(['adminHome', 'adminTransactions'], {
            queryParams: { 'sevaNo': donor.sevaNumber, 'tileName': donor.tileName }
        });
    }

    search() {
        if (this.dateRangeValue) {
            console.log(this.dateRangeValue);
            this.fromDate = new Date(this.dateRangeValue[0]);
            this.toDate = new Date(this.dateRangeValue[1]);
        } else {
            this.fromDate = undefined;
            this.toDate = undefined;
        }
        let req = {
            email: this.email,
            to: this.toDate,
            from: this.fromDate,
            mobile: this.mobile,
            sevaNumber: this.sevaNo
        };
        if (this.sevaNo) {
            req.sevaNumber = parseInt(this.sevaNo);
        }
        this.http.post(ApiConfigs.ADMIN_DONATIONS, req,
            (data) => {
                data.data.data.forEach(o => {
                    if (o.mobile) {
                        o.mobile = o.mobile.replace(/[^0-9]/g, '');
                    }
                });
                this.donationsAdminList = data.data.data;
                this.selectedItem = this.donationsAdminList[0];
                this.pageNo = 0;
            }, (error) => {
                alert(error.message);
            });
    }

    reSync() {
        document.getElementById('reSyncPopup').style.display = 'block';
    }
    close() {
        document.getElementById('reSyncPopup').style.display = 'none';
    }

    submitOne() {

        let request = {
            FromDate: null,
            ToDate: null,
            sevaNumber: null,
        };

        // if (this.selectedOne1 == 'Donor') {

        //     if (this.fromDate1 && !(this.toDate1)) {
        //         alert('Please Fill To Date !');
        //         return;
        //     }
        //     if (!(this.fromDate1) && (this.toDate1)) {
        //         alert('Please Fill From Date !');
        //         return;
        //     }
        //     if (this.fromDate1 && this.toDate1 && !(this.sevaNumber1)) {
        //         request.FromDate = this.fromDate1;
        //         request.ToDate = this.toDate1;
        //     }
        //     if (!(this.fromDate1 && this.toDate1) && this.sevaNumber1) {
        //         request.sevaNumber = this.sevaNumber1;
        //     }
        //     if (!this.fromDate1 && !this.toDate1 && !this.sevaNumber1) {
        //         alert('Please Enter Atleast Any One Value (Date or Seva No.)');
        //         return;
        //     }
        //     if (this.fromDate1 && this.toDate1 && this.sevaNumber1) {
        //         request.FromDate = this.fromDate1;
        //         request.ToDate = this.toDate1;
        //         request.sevaNumber = this.sevaNumber1;
        //     }
        //     console.log(request);
        //     alert('Your Request is Submitted. Please Wait... Your data is Update in few moment');
        //     this.http.post(ApiConfigs.ADMIN_DONOR_SYNC, request,
        //         (data) => {
        //             if (data.message) {
        //                 alert(data.message);
        //                 this.close();
        //             }
        //         }, (error) => {
        //             alert(error.message);
        //         });

        // } else 

        if (this.fromDate1 && this.toDate1) {
            let date1 = new Date(this.fromDate1)
            let date2 = new Date(this.toDate1)
            if (date2 > date1) {
                date1.setDate(date1.getDate() + 6);
                if (date2 > date1) {
                    alert('Date Range Should Be Under 7 Days !');
                    return;
                }
            }
        }

        if (this.fromDate1 && this.toDate1) {
            if (this.toDate1 < this.fromDate1) {
                alert('To Date Not be Smaller Than From Date !');
                return;
            }
        }
        if (this.fromDate1 && !(this.toDate1)) {
            alert('Please Fill To Date !');
            return;
        }
        if (!(this.fromDate1) && (this.toDate1)) {
            alert('Please Fill From Date !');
            return;
        }
        if (this.fromDate1 && this.toDate1 && !(this.sevaNumber1)) {
            request.FromDate = this.fromDate1;
            request.ToDate = this.toDate1;
        }
        if (!(this.fromDate1 && this.toDate1) && this.sevaNumber1) {
            request.sevaNumber = this.sevaNumber1;
        }
        if (!this.fromDate1 && !this.toDate1 && !this.sevaNumber1) {
            alert('Please Enter Atleast Any One Value (Date or Seva No.)');
            return;
        }
        if (this.fromDate1 && this.toDate1 && this.sevaNumber1) {
            request.FromDate = this.fromDate1;
            request.ToDate = this.toDate1;
            request.sevaNumber = this.sevaNumber1;
        }
        console.log(request);
        alert('Your Request is Submitted. Please Wait... Your data is Update in few moment');
        this.http.post(ApiConfigs.ADMIN_DONATION_SYNC, request,
            (data) => {
                if (data.message) {
                    alert(data.message);
                    this.close();
                }
            }, (error) => {
                alert(error.message);
            });

    }


    tileAlocation(selectedItem) {
        this.visiblebtn = true;
        this.disableBtn = false;
        this.sevaNum = selectedItem.sevaNumber;
        //  this._id=this.selectedItem.wall.name;
        document.getElementById('tileReAllocation').style.display = 'block';
        this.http.get(ApiConfigs.ADMIN_GET_WALL, {},
            (data) => {
              this.walls = data.data;
            }, (error) => {});
    }
    close1() {
        document.getElementById('tileReAllocation').style.display = 'none';
       this._id = '';

    }

    changeTile() {
        let request = {
            Wall_id: null,
            sevaNumber: null,
        };
        if ( !(this._id)) {
            alert('Please Fill Both Details');
            return;
        }
        if ( !(this.sevaNum)) {
            alert('Please Fill Both Details');
            return;
        }
        request.sevaNumber = this.sevaNum;
        request.Wall_id = this._id;
        console.log(request);
        this.disableBtn = true;
        this.visiblebtn = false;
        this.http.post(ApiConfigs.ADMIN_UPDATE_TILE_ALLOCATION, request,
            (data) => {

                this.visiblebtn = true;
                 this.disableBtn = false;

                if (data.message) {
                    alert(data.message);
                    this.close1();
                    this.scrollEvent = true;
                    this.pageNo = 0;
                    this.donationsAdminList = [];
                    this.getAdminData(0);
                }
            }, (error) => {
                alert(error.message);
                this.visiblebtn = true;
                this.disableBtn = false;
            });

    }



    downloadSevaList() {
        console.log('Donwload Seva List...');
        if (this.dateRangeValue) {
            console.log(this.dateRangeValue);
            this.fromDate = new Date(this.dateRangeValue[0]);
            this.toDate = new Date(this.dateRangeValue[1]);
        } else {
            this.fromDate = undefined;
            this.toDate = undefined;
        }
        let req = {
            email: this.email,
            to: this.toDate,
            from: this.fromDate,
            mobile: this.mobile,
            sevaNumber: this.sevaNo
        };
        if (this.sevaNo) {
            req.sevaNumber = parseInt(this.sevaNo);
        }

        this.http.post(ApiConfigs.ADMIN_DOWNLOAD_SEVA, req,
            (data) => {
                console.log('Donwload Seva List Done...');
                const link = data.url;
                window.open(link, '_blank');
            }, (error) => {
                alert(error.message);
            });
    }

    onScroll() {
        if (this.scrollEvent) {
            console.log('scrolled!!');
            this.pageNo = this.pageNo + 1;
            console.log(this.pageNo);
            this.getAdminData(this.pageNo);
        }
    }

    viewBrick(donor) {
        this.router.navigate(['home', 'viewBrick'], {
            queryParams: { '_id': donor._id, 'from': 'admin' }
        });
    }

    // errorpopup(){
    //  const Swal = require('sweetalert2');
    //       Swal.fire({
    //              icon: 'success',
    //              title: 'success',
    //              text: 'Downloading start please wait for some time...',
    //              confirmButtonColor: 'green',
    //              timer : 10000
    //          })
    //
    //  }
}
