import { UserData } from './../../../services/UserData';
import { ServerConfigs } from './../../../Configs/serverConfigs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-side-bar',
  templateUrl: './admin-side-bar.component.html',
  styleUrls: ['./admin-side-bar.component.css']
})
export class AdminSideBarComponent implements OnInit {

// tslint:disable-next-line: no-output-on-prefix
  @Output() onSelectChange = new EventEmitter();
  constructor(private router: Router,
    public userData: UserData) { }

  ngOnInit() {
  }

  onChange() {
    this.onSelectChange.emit();
  }
}
