import { UserData } from './../../services/UserData';
import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../services/HttpClientService';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiConfigs } from '../../Configs/api-Configs';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {
  otpSent = false;
  mobile;
  otp;
  timeleft;
  disableSend = false;
  otpMobile;

  constructor(private router: Router,
    private http: HttpClientService,
    private route: ActivatedRoute,
    private userData: UserData) {
    const token = localStorage.getItem('admintoken');
    if (token) {
      this.router.navigate(['adminHome/adminControl']);
    } else {
      localStorage.clear();
    }
  }

  ngOnInit() {
  }



  sendOtp() {
    const mContext = this;
    if (!this.disableSend) {
      this.http.get(ApiConfigs.SEND_OTP + this.mobile,
        {type: 'admin'},
        (data) => {
          mContext.otpSent = true;
          mContext.disableSend = true;
          mContext.timeleft = 40;
          const timerId = setInterval(() => mContext.timeleft > 0 && mContext.timeleft--, 1000);
          setTimeout(() => {
            clearInterval(timerId);
            mContext.timeleft = null;
            mContext.disableSend = false;
          }, 40000);
        }, (error) => {
          this.otpSent = false;
          this.disableSend = false;
          alert(error.message);
        });
    }
  }

  login() {
    const mContext = this;
    this.http.post(ApiConfigs.ADMIN_LOGIN,
      { mobile: mContext.mobile, otp: mContext.otp },
      (data) => {
        localStorage.setItem('admintoken', data.token);
        localStorage.setItem('adminmobilenumber', mContext.mobile);
        mContext.userData.admintokenAuth.next(data.token);
        console.log(data.token);

        this.router.navigate(['adminHome']);


      }, (error) => {
        alert(error.message);
      });
  }

}
