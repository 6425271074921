import { Component, OnInit } from '@angular/core';
import {ApiConfigs} from '../Configs/api-Configs';
import {ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {HttpClientService} from 'src/app/services/HttpClientService';
@Component({
  selector: 'app-user-logs-component',
  templateUrl: './user-logs-component.component.html',
  styleUrls: ['./user-logs-component.component.css']
})
export class UserLogsComponentComponent implements OnInit {
  users: any = [];
  pageNo: any = 0;
  sevaNo: any;
  mobile: any;
  scrollEvent: any = true;

  constructor(
      private http: HttpClientService,
      private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.getUserQuery(this.pageNo);
  }

  getUserQuery(pageNo) {
    let req = {
        no_of_docs : 15,
        limit: 15,
       skip: pageNo,
    };
    this.http.post(ApiConfigs.ADMIN_USER_LOGS, req,
        (data) => {
          if (data && data.data && data.data.length > 0) {
           data.data.forEach(o => {
                if (o.mobile) {
                    o.mobile = o.mobile.replace(/[^0-9]/g, '');
                }
            });
            this.users.push(...data.data);
            // this.selectedItem = this.donationsAdminList[0];
        } else {
            this.scrollEvent = false;
        }
        }, (error) => {
          alert(error.message);
        });
  }
  onScroll() {
    if (this.scrollEvent) {
        console.log('scrolled!!');
        this.pageNo = this.pageNo + 1;
        console.log(this.pageNo);
        this.getUserQuery(this.pageNo);
    }
}

downloadLogList() {
 let req = { };
  this.http.post(ApiConfigs.ADMIN_DOWNLOAD_LOGS, req,
      (data) => {
          console.log('Donwload Logs List Done...');
          const link = data.url;
          window.open(link, '_blank');
      }, (error) => {
          alert(error.message);
      });
}
// errorpopup(){
//   const Swal = require('sweetalert2');
//        Swal.fire({
//               icon: 'success',
//               title: 'success',
//               text: 'Downloading start please wait for some time...',
//               confirmButtonColor: 'green',
//               timer : 10000
//           })
//
//   }

}
