import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ApiConfigs} from '../Configs/api-Configs';
import {HttpClientService} from '../services/HttpClientService';
import {ServerConfigs} from '../Configs/serverConfigs';
import {UserData} from "../services/UserData";

declare const $: any;

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  order: any;
  schemes = [];
  adminToken;
  otpSent = false;
  mobile;
  otp;
  timeleft;
  disableSend = false;
  otpMobile;
  adminData;

  private serverConfigs = new ServerConfigs();
  submitApi = this.serverConfigs.httpHost + ApiConfigs.PAY_SUCCESS;
  constructor(private http: HttpClientService,
              private userD: UserData,
              private router: Router) {
    this.order = {
      count: 1
    };
    const order = JSON.parse(localStorage.getItem('order'));
    if (order) {
      this.order = order;
    }
    userD.admintokenAuth.subscribe(adminTokenValue => {
      this.adminToken = adminTokenValue || '';
    });
    userD.adminData.subscribe(adminData => {
      this.adminData = adminData || {};
    });
  }

  ngOnInit() {
    const adminToken = localStorage.getItem('admintoken');
    if (adminToken) {
      this.schemes = [{
        name: '100/- Monthly for 5 years per Brick',
        value: 60
      }, {
        name: '200/- Monthly for 30 months per Brick',
        value: 30
      }, {
        name: '500/- Monthly for 12 months per Brick',
        value: 12
      }, {
        name: '1000/- Monthly for 6 month per Brick',
        value: 6
      }, {
        name: '6000/- One Time per Brick',
        value: 1
      }];
    } else {
      this.schemes = [{
        name: '100/- Monthly for 5 years per Brick',
        value: 60
      }, {
        name: '200/- Monthly for 30 months per Brick',
        value: 30
      }, {
        name: '500/- Monthly for 12 months per Brick',
        value: 12
      }, {
        name: '1000/- Monthly for 6 month per Brick',
        value: 6
      }, {
        name: '6000/- One Time per Brick',
        value: 1
      }];
      this.order.installments = 1;
      this.schemeChange();
    }
  }

  schemeChange() {
    this.order.netPrice = this.order.count * 6000;
    if (this.order.installments > 0) {
      this.order.emi = (this.order.netPrice / this.order.installments) || 100;
      this.order.amount = this.order.emi;
    } else {
      this.order.amount = this.order.count * 6000 ;
      this.order.emi = 0;
    }
  }

  sendOtp() {
    const mContext = this;
    if (!this.disableSend) {
      this.http.get(ApiConfigs.ADMIN_OTP,
        {},
        (data) => {
          mContext.otpSent = true;

        }, (error) => {
          alert(error.message);
        });
    }
  }

  openPop(modal) {
    ($(modal)).modal('show');
  }

  closePopup(modal: String) {
    ($(modal)).modal('hide');
  }

  receiveCash() {
    if (!this.order.name || !this.order.amount || !this.order.installments || this.order.count < 1
        || !this.order.otp || !this.order.mode || !this.order.paymentId) {
      alert ('Fill the form!');
      return;
    } else {
      this.http.post(ApiConfigs.RECEIVE_CASH ,
        { order: this.order},
        (data) => {
          alert(data.message);
          this.closePopup('#cashReceive');
          localStorage.setItem('cart', '{}');
          localStorage.setItem('order', JSON.stringify({count: data.count }));
          this.router.navigate(['/home/room']);
        }, (error) => {
          alert(error.message);
        });
    }
  }
  /*addOrder() {
    if (!this.order.amount || !this.order.installments || this.order.count < 1) {
      alert ('Fill the form!');
      return;
    }
    localStorage.setItem('order', JSON.stringify(this.order));
    this.router.navigate(['home', 'room']);
  }*/

  addOrder() {
    if (!this.order.name || !this.order.amount || !this.order.installments || this.order.count < 1) {
      alert ('Fill the form!');
      return;
    } else {
      this.http.post(ApiConfigs.PAY ,
        { order: this.order},
        (data) => {
          this.launchPay(data.data);
        }, (error) => {
          alert(error.message);
        });
    }
    // this.launchPay();
  }

  launchPay(bkOrder) {
    let btn;
    if (btn = $('[value="Pay"]')[0]) {
      btn.click();
    } else {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.type = 'text/javascript';
      script.onload = () => {
        $('[value="Pay"]')[0].click();
      };
      script.setAttribute('data-key', this.serverConfigs.razorkey);
      script.setAttribute('data-amount', bkOrder.amount);
      script.setAttribute('data-order_id', bkOrder.id);
      script.setAttribute('data-currency', 'INR');
      script.setAttribute('data-buttontext', 'Pay');
      script.setAttribute('data-name', 'ISKCON Dwarka');
      script.setAttribute('data-description', `Donate ${this.order.count} Tile`);
      script.setAttribute('data-image', './assets/iskcon-logo.png');
      // script.setAttribute('data-prefill.name', 'Nipun Bhardwaj');
      // script.setAttribute('data-prefill.email', 'test@nipunbhardwaj.com');
      script.setAttribute('data-theme.color', '#00008B');
      const payForm = document.getElementById('payForm');
      payForm.innerHTML = '';
      payForm.appendChild(script);
    }
  }
}
