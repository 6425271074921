import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from '../services/HttpClientService';
import { ApiConfigs } from '../Configs/api-Configs';
import { ServerConfigs } from '../Configs/serverConfigs';

declare const $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  filter: any;
  donors: any;
  private serverConfigs = new ServerConfigs();
  // submitApi = '/home/paysuccess';
  submitApi = this.serverConfigs.httpHost + ApiConfigs.PAY_SUCCESS;
  owner: any;
  emi: any;
  donors1: any;
  showPay = false;
  uName = '';
  userQuery: any;
  mobile:any;
  name:any;
  donor_id: any;
  details: any;
  selectedItem;
  donor_profile: any;

  constructor(private router: Router,
    private http: HttpClientService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParam => {
      this.filter = { mobile: queryParam.mobile, email: queryParam.email };
      this.getDonors(this.filter);
    });
    this.owner = { _id: null };
    this.emi = {};
  }

  private getDonors(filter) {
    this.http.post(ApiConfigs.GET_DONATIONS, {filter},
      (data) => {
        this.donors = data.data;
        this.donor_profile = data.donor;
        data.data.forEach(o => {
          if (o.mobile) {
              o.mobile = o.mobile.replace(/[^0-9]/g, '');
          }
      });
        if (this.donors.length < 1) {
          this.router.navigate(['home', 'room'], {
            queryParams: {mode: 'view'}
          });
        }
      }, (error) => {
        alert(error.message);
      });
  }

  ngOnInit() {
  }

  viewBrick(donor) {
    this.router.navigate(['home', 'viewBrick'], {
      queryParams: { '_id': donor._id, 'wall_id': donor.wall, 'from': 'user'}

  });
    if (donor &&  donor._id) {
      this.http.post(ApiConfigs.ADD_USER_LOG, {_id: donor._id},
        (data) => {}, (error) => {});
      }
  }
  viewTransaction(donor) {
    this.router.navigate(['home', 'allTransactions'], {
      queryParams: { 'sevaNo': donor.sevaNumber, 'tileName': donor.tileName }
    });
  }

  searchDonor() {
    this.router.navigate(['dashboard'], {
      queryParams: this.filter
    });
  }

  payEMI() {
    if (!this.owner._id) {
      ($('#emipop')).modal('hide');
      alert('Please select brick!');
      return;
    }
    this.http.post(ApiConfigs.PAY_EMI + this.owner._id, this.emi,
      (data) => {
        // this.owner = {_id: null};
        // this.emi = {};
        this.launchPay(data.data);
        // ($('#emipop')).modal('hide');
        // this.getDonors(this.filter);
      }, (error) => {
        alert(error.message);
      });
  }
  
  updateName() {
    this.http.put(ApiConfigs.UPDATE_NAME + this.owner._id,
      { name: this.uName },
      (data) => {
        this.closePopup('#nameEdit');
        this.getDonors(this.filter);
        if (data.message) {
          alert(data.message);
        }
      }, (error) => {
        alert(error.message);
      });
  }

  openPop(modal, donor) {
    this.owner = donor;
    this.emi = {};
    this.emi.amount = (this.owner.committed / this.owner.scheme);
    this.uName = this.owner.tileName;
    ($(modal)).modal('show');
  }

  closePopup(modal: String) {
    this.owner = {_id: null};
    this.showPay = false;
    this.uName = '';
    ($(modal)).modal('hide');
  }

  launchPay(bkOrder) {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.type = 'text/javascript';
    script.onload = () => {
      $('[value="Pay"]')[0].click();
    };
    script.setAttribute('data-key', this.serverConfigs.razorkey);
    script.setAttribute('data-amount', bkOrder.amount);
    script.setAttribute('data-order_id', bkOrder.id);
    script.setAttribute('data-currency', 'INR');
    script.setAttribute('data-buttontext', 'Pay');
    script.setAttribute('data-name', 'ISKCON Dwarka');
    script.setAttribute('data-description', `Pay Installment`);
    script.setAttribute('data-image', './assets/iskcon-logo.png');
    // script.setAttribute('data-prefill.name', 'Nipun Bhardwaj');
    // script.setAttribute('data-prefill.email', 'test@nipunbhardwaj.com');
    script.setAttribute('data-theme.color', '#00008B');
    document.getElementById('emiPayForm').innerHTML = '';
    document.getElementById('emiPayForm').appendChild(script);

  }
 //  addQuery(): any {
 // this.addQuery()
 //  }
 //  donores: any;

  submitQuery() {
    this.http.post(ApiConfigs.USER_REQUEST_QUERY,
        {name: this.details.Name, mobile: this.details.dTelephone
          , request: this.userQuery },
        (data) => {console.log(this.donor_id);
          if (data.message) {
            alert('Query submited');
            this.closeForm();
          }
        }, (error) => {
          alert(error.message);
        });
    // let donor_id = this.donors._id;
    // const request = this.userQuery;
    // console.log(request);
  }

openForm(donor_profile) {
  if (donor_profile) {
    document.getElementById('popupForm').style.display = 'block';
    // this.donor_id = donor_profile._id;
    this.details = donor_profile;
    this.details.aDate = donor_profile['Activation Date'];
    this.details.sector = donor_profile['Area/ Sector'];
    this.details.dobi = donor_profile['Date of Brahmana Initiation'];
    this.details.dohi = donor_profile['Date of Harinama Initiation'];
    this.details.doi = donor_profile['Date of Initiation'];
    this.details.dEducation = donor_profile['Devotee Education'];
    this.details.dAddress = donor_profile['Devotee Address'];
    this.details.dCompany = donor_profile['Devotee Company Name'];
    this.details.dDataType = donor_profile['Devotee Data Type'];
    this.details.dCity = donor_profile['Devotee City'];
    this.details.dEmail = donor_profile['Devotee Email Id'];
    this.details.dGender = donor_profile['Devotee Gender'];
    this.details.dMotherTongue = donor_profile['Devotee Mother Tongue'];
    this.details.dBusiness = donor_profile['Devotee Nature of Business'];
    this.details.dPincode = donor_profile['Devotee Pin code'];
    this.details.dProfession = donor_profile['Devotee Profession'];
    this.details.dSkill = donor_profile['Devotee Skill'];
    this.details.dTalent = donor_profile['Devotee Special Talent'];
    this.details.dState = donor_profile['Devotee State/ Province'];
    this.details.dTelephone = donor_profile['Devotee Telephone Number'];
    this.details.fcbday = donor_profile['First Child Birthdate'];
    this.details.fcName = donor_profile['First Child Name'];
    this.details.scbday = donor_profile['Second Child Birthdate'];
    this.details.scName = donor_profile['Second Child Name'];
    this.details.eDate = donor_profile['Form Enrollment Date'];
    this.details.iStatus = donor_profile['Individual Status'];
    this.details.iName = donor_profile['Initiation Name'];
    this.details.jRound = donor_profile['Japa Round'];
    this.details.prd = donor_profile['Payment Realization date'];
    this.details.rs = donor_profile['Role in Society'];
    this.details.dob = donor_profile['Self Birthday'];
    this.details.panNo = donor_profile['PAN No'];
    this.details.society = donor_profile['Society Name'];
    this.details.spouseBday = donor_profile['Spouse Birthday'];
    this.details.sName = donor_profile['Spouse Name'];
    this.details.miscRmk = donor_profile['Misc Remarks'];

     //  let mobileNo =  donor_profile['Devotee Telephone Number'];
     // let phoneNo = mobileNo.replace(/[^0-9]/g, '');
     // this.details.DevoteeTelephoneNumber = phoneNo;
     console.log(this.details);
  }}
  closeForm() {
    document.getElementById('popupForm').style.display = 'none';
  }

//   showProfile(value) {
//     console.log(value);
//     if (value === this.selectedItem) {
//         this.selectedItem = '';
//     } else {
//         this.selectedItem = value;
//     }
// }
}
