import { Component, OnInit } from '@angular/core';
import { ApiConfigs } from '../Configs/api-Configs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClientService } from '../services/HttpClientService';

declare const $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  otpSent = false;
  mobile;
  otp;
  timeleft;
  disableSend = false;
  otpMobile;

  constructor(private router: Router,
    private http: HttpClientService,
    private route: ActivatedRoute) {
    const usertoken = localStorage.getItem('token');
    const adminToken = localStorage.getItem('admintoken');
    console.log(usertoken);
    console.log(adminToken);
    if (usertoken) {
      this.router.navigate(['home']);
    } else if (adminToken) {
      this.router.navigate(['']);

    } else if (usertoken && adminToken) {
      this.router.navigate(['home']);

    } else {
      localStorage.clear();
    }
  }

  ngOnInit() {
  }



  sendOtp() {
    const mContext = this;
    if (!this.disableSend) {
      this.http.get(ApiConfigs.SEND_OTP + this.mobile,
        {},
        (data) => {
          mContext.otpSent = true;
          mContext.disableSend = true;
          mContext.timeleft = 40;
          const timerId = setInterval(() => mContext.timeleft > 0 && mContext.timeleft--, 1000);
          setTimeout(() => {
            clearInterval(timerId);
            mContext.timeleft = null;
            mContext.disableSend = false;
          }, 40000);
        }, (error) => {
          alert(error.message);
        });
    }
  }

  login() {

    const mContext = this;
    this.http.post(ApiConfigs.LOGIN,
      { mobile: mContext.mobile, otp: mContext.otp },
      (data) => {

        localStorage.setItem('token', data.token);
        localStorage.setItem('usermobilenumber', mContext.mobile);
        mContext.router.navigate(['home']);

      }, (error) => {
        alert(error.message);
      });
  }

  showBrochure() {
    $('#brochure').addClass('hidden');
  }
}
