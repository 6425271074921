import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClientService } from 'src/app/services/HttpClientService';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiConfigs } from 'src/app/Configs/api-Configs';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  admin: any;
  private mode = 1;
  private getId: number;
  admindata: any;

  constructor(private router: Router,
    private http: HttpClientService,
    private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParam => {
      if (queryParam._id) {
        this.mode = 2;
        this.getId = queryParam._id;
        this.getIdData();
      }
    });
  }

  profileForm = new FormGroup({
    name: new FormControl('', Validators.required),
    mobile: new FormControl('', [Validators.required, Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  ngOnInit() {
  }

  addAdmin(data) {
    this.http.post(ApiConfigs.ADD_ADMIN,
      data,
      (data) => {
        this.admin = data.data;
        if (data.message) {
          alert(data.message);
        }
      }, (error) => {
        alert(error.message);
      });
  }

  formHandler() {
    const values = this.profileForm.value;

    if (this.mode === 2) {
      this.updataData(values);
    } else if (this.mode === 1) {
      this.addAdmin(values);
    } else {
      this.mode = 3;
    }




  }
  updataData(data) {
    this.http.put(ApiConfigs.UPDATE_ADMIN + this.getId, data,
      (data) => {
        if (data.message) {
          alert(data.message);
        }
      }, (error) => {
        alert(error.message);
      });
  }


  getIdData() {
    this.http.get(ApiConfigs.GET_ADMIN, { _id: this.getId },
      (data) => {
        this.admindata = data.data[0];
        this.fillFormData(this.admindata);
      }, (error) => {
        alert(error.message);
      });
  }
  fillFormData(data) {
    console.log(data);
    this.profileForm.patchValue({
      name: data && data.name || '',
      mobile: data && data.mobile || '',
      email: data && data.email || ''
    });
  }



}
