export class ServerConfigs {
  get razorkey(): string {
    return this._razorkey;
  }
  //private _httpHost = 'http://localhost:3015/';
  private _razorkey = 'rzp_test_e9AiFN9gLZfwGG';
  // private _razorkey = 'rzp_live_pR7qKaMXxYzdCO';
   private _httpHost = 'https://donate.iskcondwarka.in/';    // server link
  // private _httpHost = 'http://205.147.99.129:3015/';          // testing link
  constructor() {

  }

  get httpHost(): string {
    return this._httpHost;
  }

}
