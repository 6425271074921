import { AdminAddUserComponent } from './admin/admin-add-user/admin-add-user.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { HttpClientService } from './services/HttpClientService';
import { UserData } from './services/UserData';

import { AppComponent } from './app.component';
import { BrickSelectorComponent } from './brick-selector/brick-selector.component';
import { DonateTileComponent } from './donate-tile/donate-tile.component';
import { RoomViewComponent } from './room-view/room-view.component';
import { HomeComponent } from './home/home.component';
import { UpsertWallComponent } from './upsert-wall/upsert-wall.component';
import { UpsertRoomComponent } from './upsert-room/upsert-room.component';
import { BrickViewComponent } from './brick-view/brick-view.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { SidebarComponent } from './home/sidebar/sidebar.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderComponent } from './order/order.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminControlPanelComponent } from './admin/admin-control-panel/admin-control-panel.component';
import { AdminhomeComponent } from './admin/adminhome/adminhome.component';
import { AdminSideBarComponent } from './admin/adminhome/admin-side-bar/admin-side-bar.component';
import { AddUserComponent } from './admin/add-user/add-user.component';
import { UserFormComponent } from './user-form/user-form.component';
import { DataViewComponent } from './data-view/data-view.component';
import { InstantPayComponent } from './instant-pay/instant-pay.component';
import { AdminSevaListComponent } from './admin/admin-seva-list/admin-seva-list.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AdminTransactionsComponent } from './admin/admin-transactions/admin-transactions.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { UserLogsComponentComponent } from './user-logs-component/user-logs-component.component';
import { UserQueryComponentComponent } from './user-query-component/user-query-component.component';
import { SyncDataComponent } from './sync-data/sync-data.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'instantpay', component: InstantPayComponent },
  { path: 'adminlogin', component: AdminLoginComponent },

  {
    path: 'adminHome',
    component: AdminhomeComponent,
    children: [
      { path: '', redirectTo: 'adminControl', pathMatch: 'full' },
      { path: 'adminControl', component: AdminControlPanelComponent },
      { path: 'UserLogs', component: UserLogsComponentComponent },
      { path: 'UserQuery', component: UserQueryComponentComponent },
      { path: 'SyncData', component: SyncDataComponent },
      { path: 'adminAddUser', component: AdminAddUserComponent },
      { path: 'addUser', component: AddUserComponent },
      { path: 'adminSevaList', component: AdminSevaListComponent },
      { path: 'adminTransactions', component: AdminTransactionsComponent },
      { path: 'addRoom', component: UpsertRoomComponent },
      { path: 'addWall', component: UpsertWallComponent },
      { path: '**', redirectTo: '' }

    ]
  },
  { path: 'viewData', component: DataViewComponent },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'room', component: RoomViewComponent },
      { path: 'donate', component: DonateTileComponent },
      // { path: 'addRoom', component: UpsertRoomComponent },
      // { path: 'addWall', component: UpsertWallComponent },
      { path: 'viewBrick', component: BrickViewComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'checkout', component: CheckoutComponent },
      { path: 'order', component: OrderComponent },
      { path: 'profile', component: UserFormComponent },
      { path: 'allTransactions', component: TransactionsComponent },
// { path: 'adminControl', component: AdminControlPanelComponent },
      { path: '**', redirectTo: '' }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [
    AppComponent,
    BrickSelectorComponent,
    DonateTileComponent,
    RoomViewComponent,
    HomeComponent,
    UpsertWallComponent,
    UpsertRoomComponent,
    BrickViewComponent,
    DashboardComponent,
    LoginComponent,
    SidebarComponent,
    CheckoutComponent,
    OrderComponent,
    AdminLoginComponent,
    AdminControlPanelComponent,
    AdminhomeComponent,
    AdminSideBarComponent,
    AdminAddUserComponent,
    AddUserComponent,
    UserFormComponent,
    DataViewComponent,
    InstantPayComponent,
    AdminSevaListComponent,
    TransactionsComponent,
    AdminTransactionsComponent,
    UserLogsComponentComponent,
    UserQueryComponentComponent,
    SyncDataComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [HttpClientService, UserData, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
