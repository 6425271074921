import { Component, OnInit } from '@angular/core';
import { ApiConfigs } from 'src/app/Configs/api-Configs';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { HttpClientService } from 'src/app/services/HttpClientService';

@Component({
  selector: 'app-admin-seva-list',
  templateUrl: './admin-seva-list.component.html',
  styleUrls: ['./admin-seva-list.component.css']
})
export class AdminSevaListComponent implements OnInit {
  selectedItem;
  filter: any;
  admins: any;

  constructor(
    private http: HttpClientService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.getAdmin();
  }
  getAdmin() {
    this.http.get(ApiConfigs.GET_ADMIN, {},
      (data) => {
        this.admins = data.data;
      }, (error) => {
        alert(error.message);
      });
  }
  showIcon(value) {
    console.log(value);
    if (value === this.selectedItem) {
      this.selectedItem = '';
    } else {
      this.selectedItem = value;
    }
  }

}
