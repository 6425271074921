import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserData {
  get cartCount() {
    return this._cartCount.value;
  }
  get cart() {
    return this._cart.value;
  }

  tokenAuth = new BehaviorSubject<any>('');
  admintokenAuth = new BehaviorSubject<any>('');

  set cart(value: any) {
    this._cart.next(value);
    localStorage.setItem('cart', this.cartString);
    let cartCount = 0;
    for (const w in this.cart) {
      if (this.cart.hasOwnProperty(w)) {
        cartCount += this.cart[w].items.length;
      }
    }
    this._cartCount.next(cartCount);
  }

  _cart = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('cart')) || {});
  _cartCount = new BehaviorSubject<number>(0);
  order;
  adminData = new BehaviorSubject<any>({});
  constructor(private router: Router) {
    this.cart = JSON.parse(localStorage.getItem('cart')) || {};
  }

  setCart(wall, items) {
    const order = JSON.parse(localStorage.getItem('order'));
    if (order) {
      this.order = order;
    } else {
      alert('Please provide donation details first!');
      this.router.navigate(['home', 'order']);
      return;
    }
    if (!this.cart[wall._id] && items.length > 0) {
      const n = {};
      n[wall._id] = { data: wall, items };
      this.cart = { ...this.cart, ...n };
    } else if (this.cart[wall._id]) {
      const cart = { ...this.cart };
      if (items.length > 0) {
        cart[wall._id].items = items;
      } else {
        delete cart[wall._id];
      }
      this.cart = cart;
    }
  }

  get cartString() {
    const c = {};
    for (const w in this.cart) {
      if (this.cart.hasOwnProperty(w)) {
        c[w] = { data: this.cart[w].data, items: [] };
        for (const b of this.cart[w].items) {
          c[w].items.push({ id: b.id, price: b.price });
        }
      }
    }
    return JSON.stringify(c);
  }

  logOut() {
    console.log('user logOut');
    localStorage.clear();
    this.cart = {};
    localStorage.setItem('adminToken', this.admintokenAuth.value);
    this.tokenAuth.next('');
    this.router.navigate(['login']);
  }

  adminLogOut() {
    console.log('admin logout');
    localStorage.clear();
    this.admintokenAuth.next('');
    this.tokenAuth.next('');
    this.cart = {};
    this.router.navigate(['adminlogin']);
  }
}
