import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Room} from '../classes/Room';
import {Hotspot} from '../classes/Hotspot';
import {ActivatedRoute, Router} from '@angular/router';
import * as Marzipano from 'marzipano';
import {HttpClientService} from '../services/HttpClientService';
import {ApiConfigs} from '../Configs/api-Configs';

declare const $: any;
// Create geometry.
const geometry = new Marzipano.EquirectGeometry([{width: 4000}]);

// Create view.
const limiter = Marzipano.RectilinearView.limit.traditional(1024, 100 * Math.PI / 180);
const view = new Marzipano.RectilinearView({yaw: 0}, limiter);

@Component({
  selector: 'app-room-view',
  templateUrl: './room-view.component.html',
  styleUrls: ['./room-view.component.css']
})
export class RoomViewComponent implements OnInit, OnDestroy {
  _id;
  selectedRoom;
  rooms;
  room: Room;
  scene;
  isMoving = true;
  autorotate = Marzipano.autorotate({
    yawSpeed: 0.1,         // Yaw rotation speed
    targetPitch: 0,        // Pitch value to converge to
    targetFov: 100 * Math.PI / 180   // Fov value to converge to
  });
  viewer = null;
  mobile: number;
  mode: any;

  constructor(private router: Router,
              private http: HttpClientService,
              private route: ActivatedRoute) {
    const mContext = this;
    this.getData ({});
    this.route.queryParams.subscribe(queryParam => {
      this._id = queryParam._id;
      this.mode = queryParam.mode;
      this.getData ({_id: queryParam._id}, true);
    });
    /*this.room = new Room();
    this.room.name = 'Hall';
    this.room.image = './assets/pan1.jpg';*/
  }

  getData (filter, toView?) {
    this.http.get(ApiConfigs.GET_ROOM, filter,
      (data) => {
        if (toView) {
          this.selectedRoom = data.data[0];
          this.room = new Room (this.selectedRoom);
          this.changeImage(true);
        } else {
          this.rooms = data.data;
          if (!this.selectedRoom) {
            this._id = data.data[0]._id;
            this.selectRoom();
            /*this.selectedRoom = data.data[0];
            this.room = new Room (this.selectedRoom);
            this.changeImage(true);*/
          }
        }
      }, (error) => {});
  }
  ngOnInit() {
    // Create viewer.
    let pan;
    pan = document.createElement('div');
    pan.onclick = () => {
      console.log('clicked');
      this.idleMovement(3000);
    };
    pan.className = 'pan';
    document.getElementById('panHolder').appendChild(pan);
    this.viewer = new Marzipano.Viewer(pan);
    this.idleMovement(3000);
    // this.viewer.setIdleMovement(3000, this.autorotate);
    // this.createView();
  }

  createView() {
    // Create source.
    const source = Marzipano.ImageUrlSource.fromString('./assets/jpg/' + this.room.image);
    // Create scene.
    this.scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
    });

    // Display scene.
    this.scene.switchTo();

    for (const hs of this.room.hotspots) {
      this.plotHotspot(hs);
    }
  }

  changeImage(preserveHs?) {
    // this.viewer.stopMovement();
    let tempHs = [];
    if (preserveHs) {
      tempHs = (this.room.json()).hotspots;
    }
    if (this.scene) {
      for (let i = this.room.hotspots.length; i > 0; i--) {
        this.removeHotspot(i - 1);
      }
    }

    const tempScene = this.scene;

    // Create source.
    const source = Marzipano.ImageUrlSource.fromString('./assets/jpg/' + this.room.image);

    // Create scene.
    this.scene = this.viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      pinFirstLevel: true
    });
    // this.viewer.setIdleMovement(3000, this.autorotate);
    // Display scene.
    this.scene.switchTo({
      transitionDuration: 2000
    });
    setTimeout(() => {
      if (this.viewer.hasScene(tempScene)) {
        this.viewer.destroyScene(tempScene);
      }
      for (const hs of tempHs) {
        this.room.hotspots.push(this.plotHotspot(hs));
      }
      // this.viewer.setIdleMovement(3000, this.autorotate);
    }, 2100);

  }

  removeHotspot (index) {
    const hsContainer = this.scene.hotspotContainer();
    if (hsContainer.hasHotspot(this.room.hotspots[index].hotspot)) {
      hsContainer.destroyHotspot(this.room.hotspots[index].hotspot);
    }
    this.room.hotspots.splice(index, 1);
  }

  plotHotspot(hotspot: any) {
    const rmHs = document.getElementById('rmhs');
    const wlHs = document.getElementById('wlhs');
    let imgHotspot;
    if (hotspot.category === 'Room') {
      imgHotspot = rmHs.cloneNode();
    } else if (hotspot.category === 'Wall') {
      imgHotspot = wlHs.cloneNode();
    }
    imgHotspot.classList.remove('hidden');
    for (const cssClass of hotspot.classes) {
      imgHotspot.classList.add(cssClass);
    }
    const mContext = this;
    imgHotspot.addEventListener('click', function () {
      if (hotspot.category === 'Wall' && hotspot.wall_id) {
        mContext.router.navigate(['../donate'], {
          queryParams: { '_id': hotspot.wall_id , mode: mContext.mode, donor: {}},
          relativeTo: mContext.route});
      } else if (hotspot.category === 'Room' && hotspot.room_id) {
        mContext.router.navigate(['./'], {
          queryParams: { '_id': hotspot.room_id , mode: mContext.mode},
          relativeTo: mContext.route});
      }
    });
    (<any>imgHotspot).hotspot = this.scene.hotspotContainer().createHotspot(imgHotspot, hotspot.position);
    hotspot.hotspot = (<any>(imgHotspot)).hotspot;
    return hotspot;
  }
  selectRoom() {
    this.router.navigate(['./'], {
      queryParams: {'_id': this._id, mode: this.mode},
      relativeTo: this.route
    });
  }

  idleMovement(ms) {
    this.viewer.stopMovement();
    setTimeout(() => {
      this.viewer.startMovement(this.autorotate);
    }, ms);
  }

  ngOnDestroy() {
    this.viewer.stopMovement();
    this.viewer = null;
  }
  /*searchDonor() {
    this.router.navigate(['dashboard'], {
      queryParams: {mobile: this.mobile}});
  }*/

  /*placeHotspots() {

    for (const hotspot of this.hotspots) {
      this.addHotspot(hotspot);
    }
  }
  toggleMovement() {
    if (this.isMoving) {
      // Stop any ongoing automatic movement
      this.viewer.stopMovement();
    } else {
      // Start autorotation immediately
      this.viewer.startMovement(autorotate);
    }
    this.isMoving = !this.isMoving;
  }*/
  hideHelp() {
    $('#help').addClass('hidden');
  }

  showHelp() {
    $('#help').removeClass('hidden');
  }
}
