
export class Room {
  get name() {
    return this._name;
  }

  set name(value) {
    this._name = value;
  }
  get image() {
    return this._image;
  }

  set image(value) {
    this._image = value;
  }
  get hotspots() {
    return this._hotspots;
  }

  set hotspots(value) {
    this._hotspots = value;
  }
  private _name;
  private _image;
  private _hotspots;

  constructor(obj: any) {
    this._name = obj.name;
    this._image = obj.image;
    this._hotspots = obj.hotspots;
  }

  json() {
    const obj = {
      name: this.name,
      image: this.image,
      hotspots: []
    };
    for (const hs of this.hotspots) {
      obj.hotspots.push({
        category: hs.category,
        position: hs.position,
        room_id: hs.room_id,
        wall_id: hs.wall_id,
        classes: hs.classes
      });
    }
    return obj;
  }
}
