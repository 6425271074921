import { Component, OnInit, Input } from '@angular/core';
import {DatePipe} from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { DISABLED } from '@angular/forms/src/model';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from '../services/HttpClientService';
import {ApiConfigs} from '../Configs/api-Configs';


@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
  providers: [DatePipe]
})
export class UserFormComponent implements OnInit {
  private ngControl: any;
  private mode = 'view';
  isDisable = true;

  userData;
  profileForm = new FormGroup({
    name: new FormControl('', Validators.required),
    addr: new FormControl('', Validators.required),
    mobile: new FormControl('' , [Validators.required , Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email] ),
    donorDOB: new FormControl(''),
    donorAnnv: new FormControl('')
  });
  private origin: any;

  constructor(private router: Router,
              private http: HttpClientService,
              private route: ActivatedRoute,
              private datePipe: DatePipe) {
    const mContext = this;
    this.http.get(ApiConfigs.GET_DONOR, {},
      (body) => {
        mContext.userData = body.data;
        this.initializeProfile();
      }, (error) => {
        alert(error.message);
      });
    this.route.queryParams.subscribe(queryParam => {
      this.mode = queryParam.mode || 'view';
      this.origin = queryParam.origin;
      if (this.mode === 'edit') {
        this.isDisable = false;
      }
    });
  }


  get f() {
    return this.profileForm.controls;
  }


  submit() {

    // stop here if form is invalid
    if (this.profileForm.invalid) {
      alert('Fill the form correctly!');
    } else {
      const values = this.profileForm.value;
      this.http.put(ApiConfigs.UPDATE_DONOR,  values,
        (body) => {
          alert(body.message);
          this.userData = body.data;
          this.isDisable = true;
          if (this.origin === 'payment') {
            this.router.navigate(['/home/dashboard']);
          } else {
            this.initializeProfile();
          }
        }, (error) => {
          alert(error.message);
        });
    }
  }


  ngOnInit() {
  }

  myDate(date) {
    if (date && new Date(date)) {
      return this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    } else {
      return '';
    }
  }

  initializeProfile() {
    this.profileForm = new FormGroup({
      name: new FormControl(this.userData.name, Validators.required),
      addr: new FormControl(this.userData.addr, Validators.required),
      mobile: new FormControl(this.userData.mobile , [Validators.required , Validators.minLength(10)]),
      email: new FormControl(this.userData.email, [Validators.required, Validators.email] ),
      donorDOB: new FormControl(this.myDate(this.userData.donorDOB)),
      donorAnnv: new FormControl(this.myDate(this.userData.donorAnnv))
    });
  }

}
