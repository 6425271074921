import { Component, OnInit } from '@angular/core';
import {ApiConfigs} from '../Configs/api-Configs';
import {ActivatedRoute, Router, NavigationExtras} from '@angular/router';
import {HttpClientService} from 'src/app/services/HttpClientService';
@Component({
  selector: 'app-user-query-component',
  templateUrl: './user-query-component.component.html',
  styleUrls: ['./user-query-component.component.css']
})
export class UserQueryComponentComponent implements OnInit {
  users: any = [];
  sevaNo: any;
  mobile: any;
    pageNo: any = 0;
    scrollEvent: any = true;
    actionRemark : any;
    actionR : any;
    donor_id: any;
    donors: any;
    details: any;
  constructor(
      private http: HttpClientService,
      private route: ActivatedRoute) { }

  ngOnInit() {
    this.getUserQuery(this.pageNo);
  }

  getUserQuery(pageNo) {
    let req = {
        no_of_docs : 15,
        limit: 15,
        skip: pageNo,
    };
  

    this.http.post(ApiConfigs.ADMIN_USER_QUERY, req,
      (data) => {
          if (data.data && data.data.data && data.data.data.length > 0) {
              data.data.data.forEach(o => {
                  if (o.mobile) {
                      o.mobile = o.mobile.replace(/[^0-9]/g, '');
                  }
              });
              this.users.push(...data.data.data);
              // this.selectedItem = this.donationsAdminList[0];
          } else {
              this.scrollEvent = false;
          }
      }, (error) => {
          alert(error.message);
      });
  }
    onScroll() {
        if (this.scrollEvent) {
            console.log('scrolled!!');
            this.pageNo = this.pageNo + 1;
            console.log(this.pageNo);
            this.getUserQuery(this.pageNo);
        }
    }

    action(dono:any) {
        // let DonorData;
      
        if (dono && dono._id)
         {
          document.getElementById('actionPopup').style.display = 'block';
          this.donor_id = dono._id;
          this.details = dono;
        }
        }
        close() {
          document.getElementById('actionPopup').style.display = 'none';
          this.actionRemark='';
          this.actionR='';
        }

        submitActionRemark() {
            this.http.put(ApiConfigs.USER_ACTION_REMARK,
                {_id: this.donor_id , remark: this.actionRemark ,status: this.actionR},
                (data) => {
            console.log(this.donor_id);
                  if (data.message) {
                    alert('Remark Submited');
                    this.close();
                    this.scrollEvent = true;
                    this.pageNo=0;
                    this.users = [];
                    this.getUserQuery(0);
                  }
                }, (error) => {
                  alert(error.message);
                });
            let d = this.donor_id;
            console.log(d);
            const request = this.actionRemark;
            const request2 = this.actionR;
            console.log(request);
          }
}
