import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServerConfigs } from '../Configs/serverConfigs';
import * as URL from 'url-parse';
import { Router } from '@angular/router';
import { UserData } from './UserData';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {
  private serverConfigs = new ServerConfigs();
  constructor(private http: HttpClient,
    private userData: UserData) {
  }

  get(url, query, success, failure) {
    const httpUrl = new URL(this.serverConfigs.httpHost + url, true);
    httpUrl.query = { ...httpUrl.query, ...query };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token') || '',
      'adminauth': localStorage.getItem('admintoken') || ''
    });
    this.http.get(httpUrl.toString(), { headers })
      .subscribe({
        next: (data: any) => {
          success(data);
        },
        error: (error) => {
          if (error.status === 403) {
            alert('You are logged out!');
            this.userData.logOut();
            return;
          }
          if (error.error) {
            failure(error.error);
          } else {
            alert(error.message);
            failure(error);
          }
        }
      });
  }

  post(url, body, success, failure) {
    const httpUrl = new URL(this.serverConfigs.httpHost + url);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token') || '',
      'adminauth': localStorage.getItem('admintoken') || ''
    });
    this.http.post(httpUrl.toString(), body, { headers })
      .subscribe(
        (data: any) => {
          success(data);
        },
        (error) => {
          if (error.status === 403) {
            alert('You are logged out!');
            this.userData.logOut();
            return;
          }
          if (error.error) {
            failure(error.error);
          } else {
            alert(error.message);
            failure(error);
          }
        }
      );
  }

  put(url, body, success, failure) {
    const httpUrl = new URL(this.serverConfigs.httpHost + url);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token') || '',
      'adminauth': localStorage.getItem('admintoken') || ''
    });
    this.http.put(httpUrl.toString(), body, { headers })
      .subscribe(
        (data: any) => {
          success(data);
        },
        (error) => {
          if (error.status === 403) {
            alert('You are logged out!');
            this.userData.logOut();
            return;
          }
          if (error.error) {
            failure(error.error);
          } else {
            alert(error.message);
            failure(error);
          }
        }
      );
  }

  delete(url, body, success, failure) {
    const httpUrl = new URL(this.serverConfigs.httpHost + url);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token') || '',
      'adminauth': localStorage.getItem('admintoken') || ''
    });
    this.http.delete(httpUrl.toString(), body)
      .subscribe(
        (data: any) => {
          success(data);
        },
        (error) => {
          if (error.status === 403) {
            alert('You are logged out!');
            this.userData.logOut();
            return;
          }
          if (error.error) {
            failure(error.error);
          } else {
            alert(error.message);
            failure(error);
          }
        }
      );
  }

}
