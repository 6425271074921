import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClientService} from '../services/HttpClientService';
import {Room} from '../classes/Room';
import * as Marzipano from 'marzipano';
import {ApiConfigs} from '../Configs/api-Configs';

// Create geometry.
const geometry = new Marzipano.EquirectGeometry([{width: 4000}]);

// Create view.
const limiter = Marzipano.RectilinearView.limit.traditional(1024, 100 * Math.PI / 180);

@Component({
    selector: 'app-brick-view',
    templateUrl: './brick-view.component.html',
    styleUrls: ['./brick-view.component.css']
})
export class BrickViewComponent implements OnInit {
  private _id: String;
  private room: any;
  private Room: Room;
  private viewer: Marzipano.Viewer;
  donation: any;
  public from: string;
  private populate_wall: string;

  constructor(private router: Router,
              private http: HttpClientService,
              private route: ActivatedRoute) {
    this.route.queryParams.subscribe(queryParam => {
      this._id = queryParam._id;
      this.populate_wall = queryParam.wall_id;
      this.from = queryParam.from;
      if (this.from === 'admin') {
        this.getDonationAdmin(queryParam._id);
      } else {
        this.getDonation(queryParam._id );
     }
    });
    this.donation = [];
  }

    ngOnInit() {
        this.viewer = new Marzipano.Viewer(document.getElementById('pan'));
    }

  private getDonation(_id) {
    this.http.post(ApiConfigs.GET_DONATIONS, {_id: _id, populate_wall: this.populate_wall},
      (data) => {
        this.donation = data.data && data.data[0];
        if (!this.donation) {
          this.router.navigate(['/']);
        } else {
          this.getRoom({'hotspots.wall_id': this.donation.wall._id});
        }
      }, (error) => {
        alert(error.message);
        this.router.navigate(['/']);
      });
  }
  private getDonationAdmin(_id) {
    this.http.post(ApiConfigs.ADMIN_DONATIONS, {_id: _id},
      (data) => {
        this.donation = data.data.data[0];
        if (!this.donation) {
          this.router.navigate(['/']);
        } else {
          this.getRoom({'hotspots.wall_id': this.donation.wall._id});
        }
      }, (error) => {
        alert(error.message);
        this.router.navigate(['/']);
      });
  }

    private getDonor(_id) {
        this.http.post(ApiConfigs.GET_DONOR, {_id: _id},
            (data) => {
                this.donation = data.data && data.data[0];
                if (!this.donation) {
                    this.router.navigate(['/']);
                } else {
                    this.getRoom({'hotspots.wall_id': this.donation.wall._id});
                }
            }, (error) => {
                alert(error.message);
                this.router.navigate(['/']);
            });
    }

    private getRoom(filter) {
        this.http.get(ApiConfigs.GET_ROOM, filter,
            (data) => {
                this.room = data.data && data.data[0];
                if (!this.room) {
                    alert('Room not found!');
                    this.router.navigate(['/']);
                } else {
                    this.Room = new Room(this.room);
                    this.createView();
                }
            }, (error) => {
                alert(error.message);
                this.router.navigate(['/']);
            });
    }

    createView() {
        let wall;
        if (this.from === 'admin') {
            wall = this.room.hotspots.find((h) => (h.wall_id === this.donation.wall._id));
        } else if (this.from === 'user') {
            wall = this.room.hotspots.find((h) => (h.wall_id === this.donation.wall._id));
        } else {
            wall = this.room.hotspots.find((h) => (h.wall_id === this.donation.wall._id));
        }
        // Create source.
        const source = Marzipano.ImageUrlSource.fromString('./assets/jpg/' + this.Room.image);
        // Create scene.
        const view = new Marzipano.RectilinearView(wall.position, limiter);
        const scene = this.viewer.createScene({
            source: source,
            geometry: geometry,
            view: view,
            pinFirstLevel: true
        });

        // Display scene.
        scene.switchTo();
        const bricks = document.getElementById('bricks');
        scene.hotspotContainer().createHotspot(bricks, wall.position);

        const autorotate = Marzipano.autorotate({
            yawSpeed: 0.1,         // Yaw rotation speed
            targetPitch: wall.position.pitch,        // Pitch value to converge to
            // targetFov: Math.PI / 2,   // Fov value to converge to
            targetYaw: wall.position.yaw
        });
        // this.viewer.startMovement(autorotate);
    }

    goToWall() {
        this.router.navigate(['home', 'donate'], {
            queryParams: {'_id': this.donation.wall, donor: this.donation.donation, mode: 'view'}
        });
    }

    // 'home/dashboard'

    backToDashboard() {
      if (this.from === 'admin') {
        this.router.navigate(['adminHome']);
      } else {
        this.router.navigate(['home/dashboard']);
      }
    }
}
