import { Component, OnInit } from '@angular/core';
import {Bricks} from '../classes/Bricks';
import {HttpClientService} from '../services/HttpClientService';
import {ApiConfigs} from '../Configs/api-Configs';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {UserData} from '../services/UserData';
declare const $: any;

@Component({
  selector: 'app-donate-tile',
  templateUrl: './donate-tile.component.html',
  styleUrls: ['./donate-tile.component.css']
})
export class DonateTileComponent implements OnInit {
  origin: any;
  mobile: any;

  constructor(private http: HttpClientService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public userData: UserData) {
    this.owner = {
      name: '',
      mobile: '',
      email: '',
      amount: 0
    };
    const mContext = this;
    this.route.queryParams.subscribe(queryParam => {
      mContext.origin = queryParam.origin;
      mContext.mode = queryParam.mode;
      mContext.getWalls ({_id: queryParam._id});
      mContext.donor = queryParam.donor;
      mContext.mobile = queryParam.mobile;
      mContext.bricks.donor = queryParam.donor;
      mContext.bricks.om = queryParam.om;
      const order = JSON.parse(localStorage.getItem('order'));
      if (order) {
        this.order = order;
      } else if (this.mode !== 'view') {
        alert('Please provide donation details first!');
        this.router.navigate(['home', 'order']);
      }
    });
  }
  wallName = 'Inside TempleHall Left Wall';
  bricks = new Bricks(35, 20, 3000, this.wallName);
  _id;
  donors;
  owner: any;
  donor;
  mode;
  order: any;

  proceedToCheckout() {
    const modal = document.getElementById('checkoutModal');
    modal.classList.remove('invisible');
  }
  getWalls (filter) {
    const mContext = this;
    this.http.get(ApiConfigs.GET_WALL, filter,
      (data) => {
        mContext._id = data.data[0]._id;
        const preSelected = mContext.userData.cart[data.data[0]._id] && mContext.userData.cart[data.data[0]._id].items;
        mContext.bricks = new Bricks(data.data[0].X,
          data.data[0].Y,
          data.data[0].price,
          data.data[0].name,
          data.data[0],
          preSelected ? [...preSelected] : []);
        mContext.bricks.donor = mContext.donor;
        if (this.mode !== 'view') {
          mContext.bricks._selectedBricksWatcher.subscribe((items) => {
            mContext.userData.setCart({
              _id: data.data[0]._id,
              name: data.data[0].name,
            }, items);
            if (mContext.userData.cartCount >= mContext.order.count) {
              mContext.proceedToCheckout();
            }
          });
        }
        mContext.bricks.om = mContext.donor;
        mContext.getDonors ({wall: mContext._id});
      }, (error) => {});
  }
  getDonors (filter) {
    const mContext = this;
    this.http.post(ApiConfigs.GET_DONATIONS, filter,
      (data) => {
        mContext.donors = data.data;
        mContext.bricks.addOwners(mContext.donors, mContext.mobile);
      }, (error) => {});
  }
  ngOnInit() {
    if (this.mode !== 'view' && this.userData.cartCount >= this.order.count) {
      this.proceedToCheckout();
    }
  }

  sendOtp (mobile) {
    const mContext = this;
    this.http.get(ApiConfigs.SEND_OTP + mobile,
      {},
      (data) => {
      }, (error) => {
        alert(error.message);
      });
  }
  donate () {
    const mContext = this;
    this.http.post(ApiConfigs.DONATE,
      {
        ...this.owner,
        bricks: this.bricks.selectedIds,
        wall: this._id
      },
      (data) => {
        alert(data.message);
        mContext.bricks.deselectAll();
        mContext.getDonors ({wall: mContext._id});
        this.owner = {
          name: '',
          mobile: '',
          email: '',
          amount: 0
        };
        ($('#myModal')).modal('hide');
      }, (error) => {
      alert(error.message);
    });
  }
  onOMchange() {
    this.router.navigate(['donate'], {
      queryParams: { '_id': this._id, donor: this.donor, mode: this.mode}});
  }

  goBack() {
    this.location.back();
  }
}
