import {Component, Input, OnInit} from '@angular/core';
import {Bricks} from '../classes/Bricks';

@Component({
  selector: 'app-brick-selector',
  templateUrl: './brick-selector.component.html',
  styleUrls: ['./brick-selector.component.css']
})
export class BrickSelectorComponent implements OnInit {
  @Input() protected editMode = false;
  @Input() public bricks: Bricks;
  @Input() protected disableAll = false;
  isMousedown = false;
  JSON;
  constructor() {
    this.JSON = JSON;
  }

  ngOnInit() {
  }

  dragSelect(brick) {
    if (this.isMousedown) {
      brick.selected = !brick.selected;
    }
  }

}
