import { Component, OnInit } from '@angular/core';
import {Bricks} from '../classes/Bricks';
import {HttpClientService} from '../services/HttpClientService';
import {ApiConfigs} from '../Configs/api-Configs';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-upsert-wall',
  templateUrl: './upsert-wall.component.html',
  styleUrls: ['./upsert-wall.component.css']
})
export class UpsertWallComponent implements OnInit {
  walls;
  oWall;
  _id;
  wallName = '';
  bricks = new Bricks(10, 20, 6000, this.wallName);
  constructor(private http: HttpClientService,
              private route: ActivatedRoute,
              private router: Router) {
    const mContext = this;
    this.route.queryParams.subscribe(queryParam => {
      mContext._id = queryParam._id;
      mContext.oWall = null;
      mContext.getWalls ({_id: queryParam._id}, true);
    });
    this.getWalls({});
  }

  ngOnInit() {
  }

  getWalls (filter, forUpdate?) {
    this.http.get(ApiConfigs.GET_WALL, filter,
      (data) => {
        if (forUpdate) {
          this.oWall = data.data[0];
          this.bricks = new Bricks(this.oWall.X, this.oWall.Y, this.oWall.price, this.oWall.name);
        } else {
          this.walls = data.data;
        }
      }, (error) => {});
  }
  submit () {
    if (this.oWall && this.oWall._id) {
      this.http.post(ApiConfigs.UPDATE_WALL + this.oWall._id, this.bricks.json(),
        (data) => {
          alert(data.message);
        }, (error) => {
        });
    } else {
      this.http.post(ApiConfigs.ADD_WALL, this.bricks.json(),
        (data) => {
          alert(data.message);
        }, (error) => {
        });
    }
  }

  selectWall() {
    this.router.navigate(['adminHome/addWall'], {
      queryParams: { '_id': this._id }});
  }

}
