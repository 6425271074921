export class ApiConfigs {
// login
  static SEND_OTP = 'user/sendOtp/';
  static LOGIN = 'user/login';
  static ADMIN_LOGIN = 'user/adminlogin';
  static SEVA_DATA = 'user/sevaData';

  // wall
  static ADD_WALL = 'api/add/wall';
  static GET_WALL = 'api/get/walls';
  static UPDATE_WALL = 'api/update/wall/';

  // donor
  static GET_DONOR = 'api/get/donor';
  static UPDATE_DONOR = 'api/update/donor';
  static DONATE = 'api/donate';
  static ASSIGN_TILE = 'api/assignTile';
  static PAY = 'api/pay';
  static PAY_SUCCESS = 'user/paysuccess';
  static CAPTURE = 'user/capture';
  static GET_DONATIONS = 'api/get/donations';
  static GET_TRANSACTIONS = 'api/get/donations_tr';
  static PAY_EMI = 'api/payemi/';

  // room
  static ADD_ROOM = 'api/add/room';
  static GET_ROOM = 'api/get/rooms';
  static UPDATE_ROOM = 'api/update/room/';

  // update
  static UPDATE_NAME = 'api/updatename/';

  // admin
  static ADD_ADMIN = 'admin/user/add';
  static GET_ADMIN = 'admin/user/get';
  static UPDATE_ADMIN = 'admin/user/update/';
  static MY_DETAILS = 'admin/myDetails';
  static ADMIN_OTP = 'admin/sendOtp';
  static ADMIN_DONATIONS = 'admin/get/donations';
  static ADMIN_DOWNLOAD_SEVA = 'admin/downloadSevasData';

  // Log
  static ADD_USER_LOG = 'api/log/add';


  static RECEIVE_CASH = 'receiveCash';

  // User log
  static ADMIN_USER_LOGS = 'admin/get/log';
  static ADMIN_DOWNLOAD_LOGS = "admin/downloadLogdata"
   // user query
  static ADMIN_USER_QUERY = 'admin/get/request';
  static USER_REQUEST_QUERY = 'api/request/add';
// sync data table
static ADMIN_RESYNC_TABLE = 'admin/get/cronjob';
  // Action on query
  static USER_ACTION_REMARK ='admin/request/action';
  //Sync
  static ADMIN_DONATION_SYNC ='admin/Donation/Re_sync';
  static ADMIN_DONOR_SYNC ='admin/Donor/Re_sync';
  // Re Assign Wall
  static ADMIN_GET_WALL = 'admin/get/walls';
  static ADMIN_UPDATE_TILE_ALLOCATION = 'admin/UpdateTileAllocation';

  constructor() {
  }
}
